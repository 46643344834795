<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="info">
        <div class="detail">
          <div class="name">选择获取密码方式</div>
        </div>
      </div>
      <div class="list list1">
        <div class="listitem" @click="onCodeMode">
          <img src="@/assets/cxjl.png" class="icon" />
          <div class="text">订单号获取房间密码</div>
          <img src="@/assets/arrow.png" class="arrow" />
        </div>
      </div>
      <div class="list list2">
        <div class="listitem" @click="onLoseFaceMode">
          <img src="@/assets/saolian.png" class="icon" />
          <div class="text">人脸识别获取房间密码</div>
          <img src="@/assets/arrow.png" class="arrow" />
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  data() {
    return {
      openId:'',
      roomcode:''
    };
  },
  methods: {   
    async onLoseFaceMode() {
      window.location.href = "http://ywfwpt.ywkj.online/ctid/TldjService/dj_xzinroom?openid="+this.openId+"&roomcode="+this.roomcode;
    },
    async onCodeMode() {
      this.$router.replace({path:'/barcounter/code_mode', query:{openId:this.openId,roomcode:this.roomcode}});
    }
  },
  created() {
    this.openId = this.$route.query.openId;
    this.roomcode = this.$route.query.roomcode;
  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 160px;
}
.content {
  margin-top: 60px;
}
.list {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  &.list2 {
    margin-top: 20px;
  }
  &.list1 {
    margin-top: 110px;
  }
  .listitem {
    overflow: hidden;
    height: 100px;
    border-radius: 8px;
    .icon {
      width: 64px;
      margin: 18px 0 18px 40px;
      display: block;
      float: left;
    }
    .arrow {
      float: right;
      display: block;
      margin-top: 32px;
      margin-right: 38px;
      height: 40px;
    }
    .text {
      font-size: 32px;
      float: left;
      margin-left: 20px;
      line-height: 44px;
      margin-top: 30px;
    }
    .right {
      line-height: 40px;
      color: #666666;
      font-size: 28px;
      float: right;
      margin-top: 32px;
      margin-right: 38px;
    }
  }
}
.info {
  overflow: hidden;
  //margin: 90px 54px 62px;
  margin: 55px;
  img {
    width: 144px;
    height: 144px;
    border-radius: 144px;
    display: block;
    float: left;
  }
  .detail {
    overflow: hidden;
    text-align: center;
    .name {
      //margin-top: 20px;
      line-height: 66px;
      color: #fff;
      font-size: 39px;
    }
    .tel {
      height: 40px;
      font-size: 28px;
      font-weight: 400;
      line-height: 40px;
      color: rgba(255, 255, 255, 1);
      opacity: 0.6;
    }
  }
}
</style>